%pfe-headline-xxl {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--xxl, map-deep-get($font, font-size, xxl));
    line-height: var(--#{$prefix}-theme--line-height--heading, map-deep-get($font, line-height, heading));
    font-weight: var(--#{$prefix}-theme--font-weight--light, map-deep-get($font, font-weight, light));
}

%pfe-headline-xl {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--xl, map-deep-get($font, font-size, xl));
    line-height: var(--#{$prefix}-theme--line-height--heading, map-deep-get($font, line-height, heading));
    font-weight: var(--#{$prefix}-theme--font-weight--light, map-deep-get($font, font-weight, light));
}

%pfe-headline-alpha {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--alpha, map-deep-get($font, font-size, alpha));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-headline-beta {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--beta, map-deep-get($font, font-size, beta));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-headline-gamma {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--gamma, map-deep-get($font, font-size, gamma));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-headline-delta {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--delta, map-deep-get($font, font-size, delta));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-headline-epsilon {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--epsilon, map-deep-get($font, font-size, epsilon));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-headline-zeta {
    font-family: var(--#{$prefix}-theme--font-family--heading, unquote(map-deep-get($font, font-family, heading)));
    font-size:   var(--#{$prefix}-theme--font-size--zeta, map-deep-get($font, font-size, zeta));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-text-lg {
    font-family: var(--#{$prefix}-theme--font-family,  unquote(map-deep-get($font, font-family, default)));
    font-size:   var(--#{$prefix}-theme--font-size--default-lg, map-deep-get($font, font-size, default-lg));
    line-height: var(--#{$prefix}-theme--line-height--default, map-deep-get($font, line-height, default));
    font-weight: var(--#{$prefix}-theme--font-weight--default, map-deep-get($font, font-weight, default));
}

%pfe-text {
    font-family: var(--#{$prefix}-theme--font-family, unquote(map-deep-get($font, font-family, default)));
    font-size:   var(--#{$prefix}-theme--font-size--default, map-deep-get($font, font-size, default));
    line-height: var(--#{$prefix}-theme--line-height--default, map-deep-get($font, line-height, default));
    font-weight: var(--#{$prefix}-theme--font-weight--default, map-deep-get($font, font-weight, default));
}

%pfe-text-sm {
    font-family: var(--#{$prefix}-theme--font-family, unquote(map-deep-get($font, font-family, default)));
    font-size:   var(--#{$prefix}-theme--font-size--default-sm, map-deep-get($font, font-size, default-sm));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}

%pfe-text-xs {
    font-family: var(--#{$prefix}-theme--font-family, unquote(map-deep-get($font, font-family, default)));
    font-size:   var(--#{$prefix}-theme--font-size--default-xs, map-deep-get($font, font-size, default-xs));
    line-height: var(--#{$prefix}-theme--line-height, map-deep-get($font, line-height));
    font-weight: var(--#{$prefix}-theme--font-weight--normal, map-deep-get($font, font-weight, normal));
}
