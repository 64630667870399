@import "variables/variables";
@import "_deprecated-styles";
@import "theme";
@import "typography";


:root {
    --pfe-tabs__tab--FontSize: 16px;
    font-family: var(--#{$prefix}-theme--font-family, unquote(map-deep-get($font, font-family, default)));
    color: var(--#{$prefix}-broadcasted--text);
}

.PFElement {
    a {
        color: var(--#{$prefix}-broadcasted--link);
        text-decoration: var(--pfe-broadcasted--link-decoration);
        @each $state in (hover, focus) {  // visited introduces bugs; add this later
            &:#{$state} {
                color: var(--#{$prefix}-broadcasted--link--#{$state});
                text-decoration: var(--pfe-broadcasted--link-decoration--#{$state});
            }
        }
    }
}

//-- COMPONENT SPECIFIC STYLES
pfe-cta {
    &[priority="primary"] a,
    &[priority="secondary"] a {
        font-size: 1em;
    }
    &:not([priority="primary"]) a,
    &:not([priority="secondary"]) a {
        text-decoration: none;
        &:after {
            margin-bottom: .25em;
        }
    }
    // IE11 CTA fix
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        &[pfe-priority="primary"] {
            background-color: #e00;
            border-color: #e00;
        }
    }
}

pfe-navigation-item {
    --pfe-theme--color--ui-base: var(--pfe-theme--color--ui-accent, #e00);  // temporary fix for nav v.51
    --pfe-theme--color--surface--accent: var(--pfe-theme--color--ui-accent, #e00);  // temporary fix
}

pfe-jump-links-nav {
    --pfe-jump-links--BorderColor: var(--pfe-theme--color--ui-accent, #e00); // temporary fix for jump-links v.50
}
