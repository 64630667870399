:root {
    --pfe-theme--font-weight--xxl:      var(--pfe-theme--font-weight--light);
    --pfe-theme--font-weight--xl:       var(--pfe-theme--font-weight--light);
    --pfe-theme--font-weight--alpha:    var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--beta:     var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--gamma:    var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--delta:    var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--epsilon:  var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--zeta:     var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight--eta:      var(--pfe-theme--font-weight--normal);
    --pfe-theme--font-weight:           var(--pfe-theme--font-weight--normal);
    --pfe-theme--line-height--xxl:      var(--pfe-theme--line-height--heading);
    --pfe-theme--line-height--xl:       var(--pfe-theme--line-height--heading);
    --pfe-theme--line-height--alpha:    var(--pfe-theme--line-height);
    --pfe-theme--line-height--beta:     var(--pfe-theme--line-height);
    --pfe-theme--line-height--gamma:    var(--pfe-theme--line-height);
    --pfe-theme--line-height--delta:    var(--pfe-theme--line-height);
    --pfe-theme--line-height--epsilon:  var(--pfe-theme--line-height);
    --pfe-theme--line-height--zeta:     var(--pfe-theme--line-height);
    --pfe-theme--line-height--eta:      var(--pfe-theme--line-height);
    --pfe-theme--line-height--default:  var(--pfe-theme--line-height);
}
