@import "variables/variables";
@import "functions/functions";
@import "maps/general";
@import "maps/typography";
@import "maps/ui-elements";
@import "maps/surface-colors";

:root {
  // MAP file: ui-elements
  @each $key, $value in $color--ui {
    --#{$prefix}-theme--color--#{$key}: #{$value};
  }

  // MAP file: surface-colors
  @each $surface, $properties in $color--surface {
    @each $property, $result in $properties {
      @if type-of($result) == "color" {
        @if $property == "background" {
          --#{$prefix}-theme--color--surface--#{$surface}: #{$result};
        } @else {
          --#{$prefix}-theme--color--surface--#{$surface}--#{$property}: #{$result};
        }
      } @else {
        --#{$prefix}-theme--color--surface--#{$surface}--#{$property}: #{$result};
      }
    }
  }

  // MAP file: ui-elements
  @each $key, $value in $border--ui {
    --#{$prefix}-theme--ui--border-#{$key}: #{$value};
  }

  // MAP file: border-surface
  @each $key, $value in $border--surface {
    @if type-of($value) != "map" {
      --#{$prefix}-theme--surface--border-#{$key}: #{$value};
    } @else {
      @each $theme, $color in $value {
        @if $theme == "default" {
          --#{$prefix}-theme--surface--border: #{$color};
        } @else {
          --#{$prefix}-theme--surface--border-#{$theme}: #{$color};
        }
      }
    }
  }

  // MAP file: general
  @each $key, $value in $logo {
    --#{$prefix}-theme--logo--#{$key}: #{$value};
  }

  // MAP file: general
  @each $key, $value in $spacing {
    --#{$prefix}-theme--#{$key}: #{$value};
  }

  // MAP file: general
  @each $key, $value in $animation {
    --#{$prefix}-theme--animation-#{$key}: #{$value};
  }

  // MAP file: general
  @each $key, $value in $box-shadow {
    --#{$prefix}-theme--box-shadow--#{$key}: #{$value};
  }

  // MAP file: general
  @each $key, $value in $z-index {
    --#{$prefix}-theme--zindex--#{$key}: #{$value};
  }

  // MAP file: ui-elements
  //@each $level, $map in $color--feedback {
  //  @each $context, $value in $map {
  //    @if $context == "default" {
  //      --#{$prefix}-theme--color--feedback--#{$level}: #{$value};
  //    }
  //    @else {
  //      --#{$prefix}-theme--color--feedback--#{$level}--#{$context}: #{$value};
  //    }
  //  }
  //}
  //@each $level, $map in $color--cve {
  //  @each $context, $value in $map {
  //    @if $context == "default" {
  //      --#{$prefix}-theme--color--cve--#{$level}: #{$value};
  //    }
  //    @else {
  //      --#{$prefix}-theme--color--cve--#{$level}--#{$context}: #{$value};
  //    }
  //  }
  //}

  // MAP file: typography
  @each $property, $result in $font-mobile {
    @each $level, $value in $result {
      --#{$prefix}-theme--#{$property}--#{$level}: #{$value};
    }
  }
  // MAP file: typography
  @media screen and (min-width: 768px) {
    @each $property, $result in $font-desktop {
      @each $level, $value in $result {
        --#{$prefix}-theme--#{$property}--#{$level}: #{$value};
      }
    }
  }

  // MAP file: typography
  @each $property, $result in $font {
    @each $level, $value in $result {
      @if $level == "default" {
        --#{$prefix}-theme--#{$property}: #{$value};
      } @else {
        --#{$prefix}-theme--#{$property}--#{$level}: #{$value};
      }
    }
  }

  // MAP file: typography
  @each $theme, $value in $color--text {
    @if $theme == "default" {
      --#{$prefix}-theme--color--text: #{$value};
    } @else {
      --#{$prefix}-theme--color--text--on-#{$theme}: #{$value};
    }
  }

  // MAP file: typography
  @each $theme, $value in $color--text-muted {
   @if $theme == "default" {
     --#{$prefix}-theme--color--text-muted: #{$value};
   }  @else {
     --#{$prefix}-theme--color--text-muted--on-#{$theme}: #{$value};
   }
  }

  // MAP file: typography
  @each $theme, $value in $color--links {
    @each $state, $color in $value {
      $context: "";
      @if $state != "default" {
        $context: "--#{$state}";
      }
      @if $theme == "default" {
        --#{$prefix}-theme--color--link#{$context}: #{$color};
      } @else {
        --#{$prefix}-theme--color--link#{$context}--on-#{$theme}: #{$color};
      }
    }
  }

  // MAP file: typography
  @each $theme, $value in $link-decoration {
    @each $state, $color in $value {
      $context: "";
      @if $state != "default" {
        $context: "--#{$state}";
      }
      @if $theme == "default" {
        --#{$prefix}-theme--link-decoration#{$context}: #{$color};
      } @else {
        --#{$prefix}-theme--link-decoration#{$context}--on-#{$theme}: #{$color};
      }
    }
  }
}
